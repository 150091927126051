import { useEffect, useRef, useState } from 'react';
import { Input, Button, Box, Textarea } from '@chakra-ui/react';
import { AssistantService } from '../../services/assistant-service';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
// import imageSrc from '../../assets/ai.png';
import imageSrc from './logo.png';
import Swal from 'sweetalert2';
import sendSvg from '../../assets/send.svg';
import autosize from 'autosize';

type AssistantProps = {
  user: any;
};

type Message = {
  content: string;
  isUser: boolean;
};

function Assistant({ user }: AssistantProps) {
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [history, setHistory] = useState<Message[]>(
    [...(user?.messages ?? [])].reverse(),
  );
  const [isLoad, setIsLoad] = useState(false);
  const { t } = useTranslation();

  async function sendMessage() {
    const markdownMessage = message.replace(/\n/g, '\\\n');
    setHistory((prev) => [...prev, { content: markdownMessage, isUser: true }]);
    setIsLoad(true);
    setMessage('');
    try {
      const receivedMessage = await AssistantService.sendMessage(
        user.id,
        markdownMessage,
      );
      setHistory((prev) => [
        ...prev,
        { content: receivedMessage.message, isUser: false },
      ]);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: t('assistant.errorTitle'),
        text: t('assistant.errorText'),
      });
    } finally {
      setIsLoad(false);
    }
  }

  useEffect(() => {
    if (textareaRef.current) autosize(textareaRef.current);
    return () => {
      if (textareaRef.current) autosize.destroy(textareaRef.current);
    };
  }, []);

  useEffect(() => {
    if (user) {
      setHistory([...(user.messages ?? [])].reverse());
    }
  }, [user]);

  //   if (!user || user.companyId !== '123') return null;
  if (!user) return null;
  return (
    <Box
      zIndex={100000}
      position="fixed"
      bottom="10px"
      left="10px"
      width="max-content"
    >
      {isOpen && (
        <>
          <Box
            shadow={
              '-3px -10px 15px -3px rgba(0, 0, 0, 0.1), -3px -4px 6px -2px rgba(0, 0, 0, 0.05),3px 10px 15px -3px rgba(0, 0, 0, 0.1), 3px 4px 6px -2px rgba(0, 0, 0, 0.05);'
            }
            rounded={'10px'}
            background={'white'}
            width={'500px'}
            maxWidth={'calc(100vw - 40px)'}
            gap={'20px'}
          >
            <Box
              shadow={'lg'}
              background={'#f5f8fa'}
              roundedTop={'10px'}
              display="flex"
              paddingInline={'20px'}
              paddingBlock={'20px'}
              justifyContent="space-between"
              alignItems={'center'}
            >
              <Box
                display="flex"
                gap={8}
                justifyContent="center"
                alignItems="center"
              >
                <img
                  width={48}
                  height={48}
                  src={imageSrc}
                  alt={t('assistant.title')}
                />
                <Box fontSize="20px" fontWeight="bold">
                  {t('assistant.title')}
                </Box>
              </Box>
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                fontSize="20px"
                bg="transparent"
              >
                ✕
              </Button>
            </Box>
            <Box
              ref={(ref) => {
                if (ref) {
                  ref.scrollTop = ref.scrollHeight;
                }
              }}
              paddingInline={'20px'}
              height={'500px'}
              maxHeight="calc(100vh - 200px)"
              overflow={'auto'}
            >
              {history.map((msg, index) => {
                return (
                  <Box
                    key={index}
                    color={msg.isUser ? 'black' : 'black'}
                    bg={!msg.isUser ? '#d4f0fd' : '#e2e2e2'}
                    padding="10px"
                    shadow={'base'}
                    margin="10px"
                    borderRadius="10px"
                    width="fit-content"
                  >
                    <ReactMarkdown>{msg.content}</ReactMarkdown>
                  </Box>
                );
              })}

              {isLoad && (
                <Box
                  color={'black'}
                  bg={'#d4f0fd'}
                  padding="10px"
                  shadow={'base'}
                  margin="10px"
                  borderRadius="10px"
                  width="fit-content"
                >
                  <Box className="ticontainer">
                    <Box className="tiblock">
                      <Box className="tidot"></Box>
                      <Box className="tidot"></Box>
                      <Box className="tidot"></Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <form
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                if (isLoad) return;
                sendMessage();
              }}
            >
              <Box
                paddingInline={'20px'}
                paddingBlock={'20px'}
                roundedBottom={'10px'}
                background={'#f5f8fa'}
                display={'flex'}
                alignItems={'center'}
                gap={2}
                shadow={
                  '0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 -4px 6px -2px rgba(0, 0, 0, 0.05);'
                }
              >
                <Textarea
                  placeholder={
                    isLoad ? t('assistant.waiting') : t('assistant.placeholder')
                  }
                  borderColor="#E2E2E2"
                  bg={'white'}
                  rounded={'10px'}
                  fontSize="16px"
                  fontWeight={400}
                  ref={textareaRef}
                  maxHeight={'200px'}
                  height={'70px'}
                  paddingInline="12px"
                  paddingBlock="20px"
                  value={message}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      if (formRef.current) formRef.current.requestSubmit();
                    }
                  }}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
                <Button
                  bg={'#45b2d1'}
                  height={49}
                  disabled={isLoad}
                  type="submit"
                  opacity={isLoad ? 0.5 : 1}
                >
                  <img width={20} height={20} src={sendSvg} alt="send" />
                </Button>
              </Box>
            </form>
          </Box>
        </>
      )}
      {!isOpen && (
        <>
          <Box
            onClick={() => setIsOpen(true)}
            cursor="pointer"
            shadow={'base'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            rounded="50%"
            color="white"
            bg="#f5f8fa"
          >
            <img
              style={{ borderRadius: '50%', width: '50px', height: '50px' }}
              src={imageSrc}
              alt={t('assistant.title')}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default Assistant;
