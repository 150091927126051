import {
  Box,
  Container,
  Text,
  Checkbox,
  FormControl,
  FormLabel,
  Button,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserService from '../../services/user-service';
import { NewsVector } from '../../assets/NewsVector';
import newsHeader from '../../assets/newsHeader.png';
import style from '../../assets/style.json';
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton';
import bg from '../../assets/bg.png';
import { Logo } from '../../components/Footer/styleComponent/Logo';
import { useTranslation } from 'react-i18next';
import { getDirection } from '../../locales/locale';
import { LanguageContext } from '../../locales/LocaleContext';

const TermOfUse = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmiting, setIsSubmitting] = useState(false);
  const { pathname } = useLocation();
  const [isShow, setIsShow] = useState(false);
  const [textDirection, setTextDirection] = useState('');

  const { locale } = useContext(LanguageContext);
  const dir = getDirection(locale);
  console.log({ locale, dir })

  useEffect(() => {
    setIsShow(!pathname.includes('p-term-of-use'));
  }, [pathname]);

  const load = async () => {
    const user = await UserService.info();
    setUserInfo(user);

    if (user.tos) setIsChecked(true);
  };

  const submit = async (e) => {
    setIsSubmitting(true);
    await UserService.acceptTos();
    setIsSubmitting(false);

    navigate('/home', { replace: true });
  };

  useEffect(() => {
    load();
  }, []);

  const [isPc] = useMediaQuery('(min-width: 1300px)');

  const chooseTextDirection = async () => {
    const userCountry = await fetch(process.env.REACT_APP_IPINFO_URL)
      .then((res) => res.json())
      .then((res) => res.country)
      .then((country) => country.toUpperCase());
    setTextDirection(userCountry === 'IL' ? 'rtl' : 'ltr');
  };

  useEffect(() => {
    chooseTextDirection();
  }, []);

  return (
    <>
      <Box position="absolute" width={'100%'} height="16px" bg="#3FB2D2"></Box>
      {isShow && (
        <Container
          backgroundColor={true ? '' : '#20273d'}
          backgroundPosition="center"
          backgroundImage={bg}
          width={'100%'}
          height={'400px'}
          maxW={'100%'}
          position="absolute"
          display="flex"
          justifyContent={'center'}
        >
          <Box marginTop={'60px'}>
            <Logo isShowStand={false} isDark={true} />
          </Box>
        </Container>
      )}
      <Container
        position={'absolute'}
        top="200px"
        maxW="full"
        minH="100vh"
        padding={isPc ? 'unset' : 0}
        bg="#f4fbff"
        centerContent
      >
        <Box
          position={'relative'}
          width="full"
          rounded={'10px'}
          maxW={style['pc-view']}
          bg={isPc ? 'white' : '#f4fbff'}
          minHeight={'300px'}
          boxShadow={isPc ? '0px 0px 25px rgba(62, 62, 62, 0.08)' : 'unset'}
          borderTop={isPc ? '9px solid #20273D' : 'unset'}
          display={'flex'}
          flexDirection="column"
          paddingBlock={'20px'}
          paddingInline="30px"
        >
          {isPc && (
            <>
              <Box
                position={'absolute'}
                right={dir === 'rtl' ? 0 : 'unset'}
                left={dir === 'rtl' ? 'unset' : 0}
                top="0"
              >
                <NewsVector />
              </Box>

              <Image
                src={newsHeader}
                position="absolute"
                // left={'34px'}
				right={dir === 'rtl' ? 34 : '0'}
                left={dir === 'rtl' ? 'unset' : 34}
                top={0}
              />
            </>
          )}

          <Text
            mb={16}
            mt={16}
            fontWeight="bold"
            fontSize={30}
            style={{ textAlign: 'center' }}
          >
            {t('termsOfUse.termsAndCopyright')}
          </Text>

          <Box paddingInlineEnd={isPc ? '200px' : '10px'}>
            <pre dir="rtl" style={{ whiteSpace: 'pre-wrap' }}>
              {t('termsOfUse.contents')}
            </pre>
          </Box>

          {userInfo && (
            <FormControl
              display="flex"
              flexDirection="column"
              gap="1rem"
              mt="2rem"
            >
              <FormLabel dir="rtl" display="flex" alignItems="center" gap="5px">
                <Checkbox
                  size="lg"
                  colorScheme="white"
                  borderColor="#3FB2D2"
                  iconColor="#3FB2D2"
                  iconSize={'17px'}
                  isChecked={isChecked}
                  onChange={() => setIsChecked((prev) => !prev)}
                />
                {t('termsOfUse.readAndApprove')}
              </FormLabel>

              <PrimaryButton
                style={{
                  disabled: !isChecked || isSubmiting,
                }}
                disabled={!isChecked || isSubmiting}
                onClick={submit}
              >
                {t('termsOfUse.proceed')}
              </PrimaryButton>
            </FormControl>
          )}
        </Box>
      </Container>
    </>
  );
};
export default TermOfUse;
