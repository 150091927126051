import { useEffect, useState, useContext, Suspense } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  position,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import Dashboard from './pages/Dashboard';
import TasksForm from './pages/TasksForm';
import Exposure from './pages/Exposure';
import AddTasksForm from './pages/AddTasksForm';
import ModifyCompany from './pages/ModifyCompany';
import ExposureDetailed from './pages/ExposureDetailed';
import Companies from './pages/Companies';
import UserService from './services/user-service';
import AuthService from './services/auth-service';
import Tasklists from './pages/Tasklists';
import Newslattter from './pages/Newsletter';
import ModifyNewslattter from './pages/ModifyNewslatter';
import Login from './pages/Login';
import TermOfUse from './pages/TermOfUse';
import 'react-phone-input-2/lib/style.css';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Reports from './pages/Reports';
import bg from './assets/bg.png';
import style from './assets/style.json';
import { WhiteSecondaryButton } from './components/Footer/styleComponent/WhiteSecondaryButton';
import { Logo } from './components/Footer/styleComponent/Logo';
import { Topbar } from './components/Topbar';
import { Sidebar } from './components/Sidebar';
import { Hamburger } from './assets/Hamburger';
import NewslattterView from './pages/NewsletterView';
import { ArrowDownIcon } from './assets/ArrowDownIcon';
import Logs from './pages/Logs';
import Contact from './pages/Contact';
import { SplashGuard } from './guard/SplashGuard';
import DowlandFile from './pages/DowloandFile';
import Logger from './pages/Logger';
import './locales/i18n';
import i18n from './locales/i18n';
import LocaleContextProvider, {
  LanguageContext,
} from './locales/LocaleContext';
import { RtlProvider } from './RtlProvider';
import AppRoutes from './AppRoute/AppRoutes';
import Assistant from './components/Assistant';
import RedButton from './components/RedButton';

function App() {
  const [currRole, setCurrRole] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<any>({});
  const [userRoles, setUserRoles] = useState<any>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNewsletterOnly, setIsNewsletterOnly] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  console.log({ userInfo });

  const { locale, setLocale } = useContext(LanguageContext);

  useEffect(() => {
    // Check if the user is authenticated
    UserService.info().then((authStatus: boolean) => {
      setIsAuthenticated(true);
      if (!authStatus) {
        <Navigate to="/" />;
      }
    });
  }, [Navigate]);

  return (
    <Suspense fallback={<div />}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        className=""
      ></div>
      <LocaleContextProvider>
        <RtlProvider>
          <Box bg={'#fafafa'} height="max-content">
            <BrowserRouter>
              <SplashGuard>
                <Topbar
                  userInfo={userInfo}
                  userRoles={userRoles}
                  currRole={currRole}
                  setCurrRole={setCurrRole}
                  setUserInfo={setUserInfo}
                  setUserRoles={setUserRoles}
                  setIsSidebarOpen={setIsSidebarOpen}
                  setIsNewsletterOnly={setIsNewsletterOnly}
                />
                <Assistant user={userInfo} />
                {userInfo && Object.keys(userInfo).length > 0 && <RedButton />}
                {isSidebarOpen && (
                  <Sidebar
                    userRoles={userRoles}
                    currRole={currRole}
                    userInfo={userInfo}
                    setCurrRole={setCurrRole}
                    setIsSidebarOpen={setIsSidebarOpen}
                    isNewsletterOnly={isNewsletterOnly}
                  />
                )}
                <AppRoutes
                  isAuthenticated={isAuthenticated}
                  currRole={currRole}
                  isNewsletterOnly={isNewsletterOnly}
                  userInfo={userInfo}
                />
              </SplashGuard>
            </BrowserRouter>
          </Box>
        </RtlProvider>
      </LocaleContextProvider>
    </Suspense>
  );
}

export default App;
