import {
  Box,
  Button,
  Container,
  Text,
  useMediaQuery,
  Flex,
  Skeleton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowDownIcon } from '../../assets/ArrowDownIcon';
import Swal from 'sweetalert2';
import UserService from '../../services/user-service';
import style from '../../assets/style.json';
import { TrashIcon } from '../../assets/TrashIcon';
import { CirclePlusIcon } from '../../assets/CirclePlusIcon';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../locales/LocaleContext';
import { EditIcon2 } from '../../assets/EditIcon2';
import GuidingQuestionnaireService, {
  GuidingQuestionnaireUser,
  GuidingQuestionnaire as GuidingQuestionnaireType,
} from '../../services/guiding-questionnaire-service';
import { InfoIcon2 } from '../../assets/Info';
import { MenuIcon } from '../../assets/MenuIcon';
import Svg from '../../assets/check-mark.svg';

function GuidingQuestionnaire() {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState<any>({});
  const [questionnaires, setQuestionnaires] = useState<
    (GuidingQuestionnaireType & { users: GuidingQuestionnaireUser[] })[]
  >([]);

  const [goingDeleteId, setGoingDeleteId] = useState<string>();
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const navigate = useNavigate();

  const { isLoading, setIsLoading } = useContext(LanguageContext);

  useEffect(() => {
    load();
  }, []);

  const remove = async (id: string) => {
    if (!isAdmin) return;

    const result = await Swal.fire({
      title: `${t('guidingQuestionnaire.delete')}?`,
      text: t('guidingQuestionnaire.deleteWarning'),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: t('guidingQuestionnaire.cancel'),
      confirmButtonText: t('guidingQuestionnaire.delete'),
    });

    if (result.isConfirmed) {
      setGoingDeleteId(id);
      await GuidingQuestionnaireService.delete(id);
      await load();
      setGoingDeleteId('');
    }
  };

  const load = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      const user = await UserService.info();
      setUserInfo(user);
      const questionnaires = await GuidingQuestionnaireService.list();
      setQuestionnaires(questionnaires as any);
    } finally {
      setIsLoading(false); // Set loading state to false after data is fetched
    }
  };

  const isAdmin = userInfo.companyId === '123';

  //   const pendingQuestionnaires = isAdmin
  //     ? questionnaires
  //     : questionnaires.filter((questionnaire) =>
  //         questionnaire.users.every((user) => user.status !== 'DONE'),
  //       );

  const userId = userInfo.id;

  const pendingQuestionnaires = isAdmin
    ? questionnaires
    : questionnaires.filter((q) => {
        const user = q.users.find((u) => (u as any).userId === userId);
        return !user || (user && user.status !== 'DONE');
      });

  console.log({ pendingQuestionnaires, userInfo });

  return (
    <Container
      position="absolute"
      top={'90px'}
      maxW="full"
      py={20}
      centerContent
    >
      {isLoading ? (
        <>
          <Flex justify="center">
            <Skeleton height="30px" width="200px" borderRadius="md" />
          </Flex>
        </>
      ) : (
        <>
          <Text color={'white'} dir="ltr" fontWeight={500} fontSize={22}>
            {`${t('guidingQuestionnaire.hello')} ${userInfo.name}`}
          </Text>
          <Text color={'white'} dir="ltr" fontWeight={500} fontSize={22}>
            {t('guidingQuestionnaire.title')}
          </Text>
        </>
      )}

      <Box
        rounded={'10px'}
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
        mt={10}
        maxW={style['pc-view']}
        w="100%"
        paddingInline={'28px'}
        bg={'white'}
        minHeight="380px"
      >
        <Box display={'flex'} flexDirection="row-reverse" width={'100%'}>
          {isLoading ? (
            <>
              <Skeleton
                height="40px"
                width="200px"
                borderRadius="3xl"
                marginBlock={'28px'}
              />{' '}
            </>
          ) : (
            <>
              {' '}
              {isAdmin && (
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={() =>
                    navigate('/admin/modify-guiding-questionnaire')
                  }
                  marginBlock="28px"
                  width={'188px'}
                  gap="8px"
                  height="44px"
                  rounded={'22px'}
                  bg={'white'}
                  color="#3FB2D2"
                  border={'1px solid #3FB2D2'}
                >
                  <Text fontSize={'18px'} fontWeight="600">
                    {t('modifyGuidingQuestionnaire.addQuestionnaire')}
                  </Text>
                  <CirclePlusIcon />
                </Button>
              )}
            </>
          )}
        </Box>
        {isLoading ? (
          <>
            {[...Array(3)].map((_, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                paddingBlock={'30px'}
                borderBottom={'1.5px solid #F0EEEE'}
              >
                <Skeleton height="40px" width="85%" borderRadius="md" />
                <Box display="flex" alignItems="center">
                  <Skeleton height="40px" width="40px" borderRadius="md" />
                  <Skeleton
                    height="40px"
                    width="40px"
                    borderRadius="md"
                    ml={2}
                  />
                  <Skeleton
                    height="40px"
                    width="40px"
                    borderRadius="md"
                    ml={2}
                  />
                </Box>
              </Box>
            ))}
          </>
        ) : pendingQuestionnaires.length ? (
          pendingQuestionnaires.map((questionnaire) => (
            <Box
              key={questionnaire.id}
              _focus={{ outline: 0 }}
              display="flex"
              justifyContent="space-between"
              paddingBlock={'30px'}
              borderBottom={'1.5px solid #F0EEEE'}
              cursor="pointer"
              onClick={() => {
                navigate(`/guiding-questionnaire/${questionnaire.id}`);
              }}
            >
              <Text
                wordBreak="break-word"
                fontWeight="bold"
                fontSize={22}
                textAlign="start"
              >
                {questionnaire.name}
              </Text>
              <Box display={'flex'} alignItems="center">
                {isAdmin && isPc ? (
                  <Box width={'180px'}>
                    <Button
                      disabled={goingDeleteId === questionnaire.id}
                      title={t('guidingQuestionnaire.users')}
                      color={'#3FB2D2'}
                      bg={'transparent'}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(
                          `/admin/modify-guiding-questionnaire/${questionnaire?.id}/users`,
                        );
                      }}
                    >
                      <InfoIcon2 />
                    </Button>

                    <Button
                      disabled={goingDeleteId === questionnaire.id}
                      title={t('guidingQuestionnaire.edit')}
                      color={'#3FB2D2'}
                      bg={'transparent'}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(
                          `/admin/modify-guiding-questionnaire/${questionnaire?.id}`,
                        );
                      }}
                    >
                      <EditIcon2 />
                    </Button>

                    <Button
                      disabled={goingDeleteId === questionnaire.id}
                      title={t('guidingQuestionnaire.delete')}
                      color={'#3FB2D2'}
                      bg={'transparent'}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        remove(questionnaire.id as string);
                      }}
                    >
                      <TrashIcon />
                    </Button>
                  </Box>
                ) : (
                  <Box
                    onClick={(ev) => {
                      ev.stopPropagation();
                    }}
                  >
                    <Menu>
                      <MenuButton bg="transparent" as={Button}>
                        <MenuIcon />
                      </MenuButton>
                      <MenuList overflowY="auto" maxH={'300px'} maxW={'150px'}>
                        <MenuItem
                          disabled={goingDeleteId === questionnaire.id}
                          color="#3FB2D2"
                          display="flex"
                          justifyContent={'space-between'}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            navigate(
                              `/admin/modify-guiding-questionnaire/${questionnaire?.id}/users`,
                            );
                          }}
                        >
                          {t('guidingQuestionnaire.users')}
                          <InfoIcon2 />
                        </MenuItem>
                        <MenuItem
                          display="flex"
                          justifyContent={'space-between'}
                          color="#3FB2D2"
                          disabled={goingDeleteId === questionnaire.id}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            navigate(
                              `/admin/modify-guiding-questionnaire/${questionnaire?.id}`,
                            );
                          }}
                        >
                          {t('guidingQuestionnaire.edit')}
                          <EditIcon2 />
                        </MenuItem>
                        <MenuItem
                          display="flex"
                          justifyContent={'space-between'}
                          color="#3FB2D2"
                          disabled={goingDeleteId === questionnaire.id}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            remove(questionnaire.id as string);
                          }}
                        >
                          {t('guidingQuestionnaire.delete')}
                          <TrashIcon />
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                )}
                {/* {isAdmin && !isPc && (
									<Box
										onClick={(ev) => {
											ev.stopPropagation();
										}}
									>
										<Menu>
											<MenuButton bg="transparent" as={Button}>
												<MenuIcon />
											</MenuButton>
											<MenuList overflowY="auto" maxH={"300px"} maxW={"150px"}>
												<MenuItem
													disabled={goingDeleteId === questionnaire.id}
													color="#3FB2D2"
													display="flex"
													justifyContent={"space-between"}
													onClick={(ev) => {
														ev.stopPropagation();
														navigate(
															`/admin/modify-newslatter/${questionnaire?.id}`,
														);
													}}
												>
													{t("guidingQuestionnaire.edit")}
													<EditIcon2 />
												</MenuItem>
												<MenuItem
													display="flex"
													justifyContent={"space-between"}
													color="#3FB2D2"
													disabled={goingDeleteId === newslatterItem.id}
													onClick={(ev) => {
														ev.stopPropagation();
														remove(newslatterItem.id);
													}}
												>
													{t("guidingQuestionnaire.delete")}
													<TrashIcon />
												</MenuItem>
											</MenuList>
										</Menu>
									</Box>
								)} */}
                <Box transformOrigin={'center'} transform="rotate(90deg)">
                  <ArrowDownIcon />
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <div className="flex flex-col my-8 items-center justify-center gap-2">
            <img width={40} height={40} src={Svg} alt="send" />

            <Text textAlign="center" fontSize={22} fontWeight="bold">
              {t('guidingQuestionnaire.noQuestionnaires')}
            </Text>
          </div>
        )}
      </Box>
    </Container>
  );
}

export default GuidingQuestionnaire;
