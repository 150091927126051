import { LanguageContext } from '../../locales/LocaleContext';
import { getDirection } from '../../locales/locale';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { useContext } from 'react';
import locationSvg from './location.svg';
import Swal from 'sweetalert2';
import imageSrc from './logo.png';
import { useNavigate } from 'react-router-dom';

function RedButton() {
  const { t } = useTranslation();
  const { locale } = useContext(LanguageContext);
  const dir = getDirection(locale);
  const nav = useNavigate()

  const handleOpenRedButtonPopup = () => {
    Swal.fire('', t('sidebar.redButtonMsg'), 'error');
  };

  const handleMoveToRedButton = () => {
	nav('/red-button')
  }

  return (
    <Box
      onClick={handleMoveToRedButton}
      cursor="pointer"
      shadow={'base'}
      display="flex"
      justifyContent="center"
      alignItems="center"
      rounded="50%"
      color="white"
      bg="#f5f8fa"
      zIndex={100000}
      position="fixed"
      bottom="10px"
      width="max-content"
      className={dir === 'rtl' ? 'left-[10px]' : 'right-[10px]'}
    >
      <img
        style={{ borderRadius: '50%', width: '50px', height: '50px' }}
        src={imageSrc}
      />
    </Box>
  );

  return (
    <Box
      zIndex={100000}
      position="fixed"
      bottom="10px"
      width="max-content"
      className={dir === 'rtl' ? 'left-[10px]' : 'right-[10px]'}
    >
      <Box
        onClick={handleOpenRedButtonPopup}
        cursor="pointer"
        // shadow={'base'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        // rounded={0}
        paddingX={3}
        paddingY={3}
        rounded="50%"
        // color="white"
        bg="#f5f8fa"
      >
        <img style={{ width: 30, height: 30 }} src={locationSvg} />
      </Box>
    </Box>
  );
}

export default RedButton;
