import {
  Box,
  Button,
  Checkbox,
  Image,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import AuthService from '../../services/auth-service';
import PhoneInput from 'react-phone-input-2';
import bgLogin from '../../assets/bgLogin.png';
import loginHeader from '../../assets/loginHeader.png';
import './index.css';
import { VerifyCode } from '../../components/VerifyCode';
import { Logo } from '../../components/Footer/styleComponent/Logo';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../locales/LocaleContext';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [identifier, setIdentifier] = useState('');
  const [isCodeSended, setIsCodeSended] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');
  const [isRemember, setIsRemember] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const [isSmall] = useMediaQuery('(min-width: 400px)');
  const [isCallLoading, setIsCallLoading] = useState(false);
  const [now, setNow] = useState(new Date());
  const [nextAvailableCall, setNextAvailableCall] = useState(now);
  const { locale, setLocale } = useContext(LanguageContext);

  const fetchLocale = async () => {
    try {
      const url = `${process.env.REACT_APP_IPINFO_URL}`;
      const response = await fetch(url);
      const data = await response.json();
      const country = data.country;
      const localeData = country === 'IL' ? 'he' : 'en';
      localStorage.setItem('locale', localeData);
      setLocale(localeData);
    } catch (error) {
      console.error('Error fetching locale:', error);
    }
  };

  useEffect(() => {
    const storedLocale = localStorage.getItem('locale');
    if (storedLocale) {
      setLocale(storedLocale);
    } else {
      fetchLocale();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setNow(new Date());
    }, 400);
  }, [now]);

  const submit = async () => {
    if (isCodeSended) {
      if (!identifier.trim() && verifyCode.length !== 5) {
        Swal.fire('', t('login.enterEmailPass'), 'warning');
        return;
      }
      try {
        let fcmToken = null;
        try {
          if ('getFCMToken' in window) {
            fcmToken = await window.getFCMToken();
          }
        } catch (error) {
          console.error('Error fetching FCM token:', error);
        }

        console.log('rid is ' + fcmToken);

        await AuthService.login(
          identifier,
          verifyCode,
          isRemember,
          //   searchParams.get('rid'),
          fcmToken,
        );
        navigate('/home');
      } catch (err: any) {
        Swal.fire('', err.response?.data?.message, 'error');
      }
    } else {
      try {
        await AuthService.sendCode(identifier);
        setIsCodeSended(true);
        console.log('Code sent to ' + identifier);
      } catch (err: any) {
        console.log({ err, res: err.response, data: err.response?.data });
        const msg = err.response?.data?.message || t('login.errorSendingCode');
        Swal.fire('', msg, 'error');
        console.error('Error sending code:', err);
      }
    }
  };

  const call = async () => {
    try {
      setIsCallLoading(true);
      await AuthService.sendCall(identifier);
    } finally {
      setIsCallLoading(false);
      setNextAvailableCall(new Date(now.getTime() + 30000));
    }
  };

  return (
    <Box
      display={'flex'}
      maxW="full"
      minH="100vh"
      flexDirection={isPc ? 'row' : 'column'}
    >
      <Box
        display={'flex'}
        alignItems="center"
        flexDirection={'column'}
        justifyContent={isPc ? 'center' : 'flex-start'}
        height={'100vh'}
        width={isPc ? '700px' : 'full'}
        position="relative"
        zIndex={'2'}
        paddingTop={isPc ? '0px' : '145px'}
      >
        <Text
          color={isPc ? '#1A202C' : 'white'}
          fontWeight="800"
          marginBottom={'100px'}
          fontSize={isPc ? 50 : 30}
        >
          {t('login.hello') + (isCodeSended ? ',' : '')}
        </Text>

        <Box
          width="500px"
          maxW={'calc(100% - 20px)'}
          paddingInline="9px"
          rounded="20px"
          marginInline={'10px'}
          bg="#F9F9F9"
          border="11px solid #fff"
          boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.18)'}
          display="flex"
          flexDirection="column"
          alignItems="center"
          position={'relative'}
        >
          {isCodeSended ? (
            <Text
              color={isPc ? '#1A202C' : 'white'}
              fontWeight="800"
              position={'absolute'}
              top="-110px"
              fontSize={isPc ? 50 : 30}
            >
              {t('login.enterCode')}
            </Text>
          ) : (
            <Image
              position={'absolute'}
              transform={'translateY(-50%)'}
              src={loginHeader}
            />
          )}

          <Text
            marginTop={isCodeSended ? '40px' : '130px'}
            marginBottom="37px"
            fontWeight="700"
            fontSize={22}
          >
            {isCodeSended ? t('login.enterMfaCode') : t('login.pleaseLogin')}
          </Text>
          {isCodeSended ? (
            <>
              <Text fontSize={isSmall ? '18px' : '16'} pb={'52px'}>
                {t('login.codeSent') + ': '}
                <Text dir="ltr" display={'inline'} fontWeight={'600'}>
                  +{identifier.slice(0, 5)}******{identifier.slice(-1)}
                </Text>
              </Text>
              <Box
                onKeyDown={(ev) => {
                  if (ev.keyCode === 13) submit();
                }}
              >
                <VerifyCode
                  code={verifyCode}
                  setCode={setVerifyCode}
                  length={5}
                />
              </Box>
              <Button
                isLoading={isCallLoading}
                disabled={now.getTime() < nextAvailableCall.getTime()}
                onClick={call}
                sx={{
                  cursor: 'pointer',
                  pb: '20px',
                  fontSize: '18px',
                  color: '#0000ff',
                  textDecoration: 'underline',
                  background: 'transparent',
                  '&:active': {
                    background: 'transparent',
                  },
                  '&:disabled': {
                    color: '#3e3e3e',
                    cursor: 'not-allowed',
                  },
                }}
              >
                {now.getTime() < nextAvailableCall.getTime()
                  ? ' ' +
                    t('login.mfaCall') +
                    ': ' +
                    t('login.mfaDeferred') +
                    Math.ceil(
                      (nextAvailableCall.getTime() - now.getTime()) / 1000,
                    ) +
                    t('login.seconds')
                  : ' ' + t('login.mfaCall')}
              </Button>
            </>
          ) : (
            <>
              <Box pb={'25px'} width={'100%'}>
                <Text color={'#475154'} fontSize="18px" fontWeight={400}>
                  {t('login.phoneNumber')}
                </Text>
                <PhoneInput
                  country={'il'}
                  value={identifier}
                  inputClass="login-phone-input"
                  onChange={(phoneNumber: string) => setIdentifier(phoneNumber)}
                  onKeyDown={(ev) => {
                    if (ev.keyCode === 13) submit();
                  }}
                />
              </Box>
              <Box width={'100%'}>
                <Checkbox
                  w="100%"
                  colorScheme="white"
                  borderColor="#3FB2D2"
                  iconColor="#3FB2D2"
                  iconSize={'17px'}
                  color="#2D2F32"
                  checked={isRemember}
                  onChange={() => setIsRemember((prev) => !prev)}
                  mb={isPc ? '54px' : '31px'}
                  size={'lg'}
                >
                  <Text fontSize={'18px'}>{t('login.rememberMe')}</Text>
                </Checkbox>
              </Box>
            </>
          )}
        </Box>
        <Button
          marginInline={'10px'}
          color={'white'}
          minW={'calc(100% - 20px)'}
          minHeight="78px"
          textAlign="center"
          border="3px solid #FFFFFF"
          background={'#3FB2D2'}
          fontSize="18px"
          fontWeight={700}
          rounded={'100px'}
          mt={isPc ? '67px' : '35px'}
          filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
          onClick={submit}
        >
          {t('login.loginButton')}
        </Button>
      </Box>
      <Box
        backgroundColor={'#20273d'}
        backgroundSize="cover"
        backgroundRepeat={'no-repeat'}
        backgroundImage={bgLogin}
        backgroundPosition="center"
        flexGrow={1}
        height={isPc ? '100vh' : '424px'}
        display="flex"
        alignItems={'flex-start'}
        paddingTop={isPc ? '160px' : '45px'}
        justifyContent={'center'}
        position={isPc ? 'static' : 'absolute'}
        width={isPc ? '' : 'full'}
        zIndex={1}
      >
        <Logo isShowStand={true} />
      </Box>
    </Box>
  );
};

export default Login;
