import { LanguageContext } from '../../locales/LocaleContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from '../../assets/style.json';
import GuidingQuestionnaireService, {
  GuidingQuestionnaire as GuidingQuestionnaireType,
} from '../../services/guiding-questionnaire-service';
import {
  Box,
  Button,
  Container,
  Text,
  Flex,
  Skeleton,
  CheckboxGroup,
  Stack,
} from '@chakra-ui/react';
import Swal from 'sweetalert2';

type QuestAnswer = {
  questionIndex: number;
  answerIndex: number;
};

function GuidingQuestionnaireTest() {
  const { t } = useTranslation();
  const [questionnaire, setQuestionnaire] =
    useState<GuidingQuestionnaireType>();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [questAnswers, setQuestAnswers] = useState<QuestAnswer[]>([]);
  const [wrongAnswers, setWrongAnswers] = useState<QuestAnswer[]>([]);
  const [rightAnswers, setRightAnswers] = useState<QuestAnswer[]>([]);
  console.log({ questAnswers });

  const { id } = useParams();

  useEffect(() => {
    loadQuestionnaire().then(() => setIsLoading(false));
    viewQuestionnaire();
  }, []);

  const loadQuestionnaire = async () => {
    if (id) {
      const selectedQuestionnaire = await GuidingQuestionnaireService.read(id);
      setQuestionnaire(selectedQuestionnaire);
    }
  };

  const viewQuestionnaire = async () => {
    if (id) {
      await GuidingQuestionnaireService.viewTest(id);
    }
  };

  const navigate = useNavigate();

  const { isLoading, setIsLoading } = useContext(LanguageContext);

  const handleSubmit = async () => {
    if (isSubmiting) return;
    if (!questionnaire) return;
    if (!questAnswers) return;
    if (!id) return;

    // Check if all questions were answered with a valid answer, if not, mark them by their index
    const wrongAnswers = questAnswers
      .map((ans) => {
        const question = questionnaire?.questions[ans.questionIndex];
        if (!question) return ans.questionIndex;

        const validAnswers = question.correctOptionIndexs;

        if (!validAnswers.includes(ans.answerIndex)) return ans.questionIndex;
        return null;
      })
      .filter((ans) => ans !== null);

    const rightAnswers = questAnswers
      .map((ans) => {
        const question = questionnaire?.questions[ans.questionIndex];
        if (!question) return ans.questionIndex;

        const validAnswers = question.correctOptionIndexs;

        if (validAnswers.includes(ans.answerIndex)) return ans.questionIndex;
        return null;
      })
      .filter((ans) => ans !== null);

    if (rightAnswers.length > 0) {
      setRightAnswers((prev) => [
        ...prev,
        ...rightAnswers.map((ans) => ({
          questionIndex: ans! as number,
          answerIndex:
            questAnswers.find((qa) => qa.questionIndex === ans)?.answerIndex ||
            0,
        })),
      ]);
    }

    if (wrongAnswers.length > 0) {
      setWrongAnswers((prev) => [
        ...prev,
        ...wrongAnswers.map((ans) => ({
          questionIndex: ans! as number,
          answerIndex:
            questAnswers.find((qa) => qa.questionIndex === ans)?.answerIndex ||
            0,
        })),
      ]);

      return Swal.fire({
        icon: 'error',
        title: t('guidingQuestionnaire.error'),
        text: t('guidingQuestionnaire.wrongAnswers'),
      });
    }

    // Check if all questions were answered
    if (questAnswers.length < (questionnaire?.questions.length || 0)) {
      return Swal.fire({
        icon: 'error',
        title: t('guidingQuestionnaire.error'),
        text: t('guidingQuestionnaire.notAllQuestionsAnswered'),
      });
    }

    setIsSubmiting(true);

    try {
      await GuidingQuestionnaireService.submitTest(id);

      Swal.fire({
        title: t('guidingQuestionnaire.success'),
        text: t('guidingQuestionnaire.successMessage'),
      });

      // Redirect
      navigate('/guiding-questionnaire');
    } catch (error) {
      console.error(error);
    }

    setIsSubmiting(false);
  };

  return !questionnaire ? (
    <>-</>
  ) : (
    <Container
      position="absolute"
      top={'90px'}
      maxW="full"
      py={20}
      centerContent
    >
      {isLoading ? (
        <>
          <Flex justify="center">
            <Skeleton height="30px" width="200px" borderRadius="md" />
          </Flex>
        </>
      ) : (
        <>
          <Text color={'white'} dir="rtl" fontWeight={500} fontSize={22}>
            {`${t('guidingQuestionnaire.test')} ${questionnaire.name}`}
          </Text>
        </>
      )}

      <Box
        rounded={'10px'}
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
        mt={10}
        maxW={style['pc-view']}
        w="100%"
        paddingInline={'28px'}
        bg={'white'}
        minHeight="380px"
      >
        {isLoading ? (
          <>
            {[...Array(3)].map((_, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                paddingBlock={'30px'}
                borderBottom={'1.5px solid #F0EEEE'}
              >
                <Skeleton height="40px" width="85%" borderRadius="md" />
                <Box display="flex" alignItems="center">
                  <Skeleton height="40px" width="40px" borderRadius="md" />
                  <Skeleton
                    height="40px"
                    width="40px"
                    borderRadius="md"
                    ml={2}
                  />
                  <Skeleton
                    height="40px"
                    width="40px"
                    borderRadius="md"
                    ml={2}
                  />
                </Box>
              </Box>
            ))}
          </>
        ) : (
          <>
            <Box
              key={questionnaire.id}
              _focus={{ outline: 0 }}
              display="flex"
              justifyContent="space-between"
              paddingBlock={'30px'}
              cursor="pointer"
              width="full"
              borderBottom={'1.5px solid #F0EEEE'}
            >
              {t('guidingQuestionnaire.testDescription')}
            </Box>
            <Box
              key={questionnaire.id}
              _focus={{ outline: 0 }}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              cursor="pointer"
              onClick={() => {
                navigate(`/guiding-questionnaire/${questionnaire.id}`);
              }}
              width="full"
            >
              {questionnaire.questions.map((question, questionIndex) => {
                const questAnswer = questAnswers.find(
                  (ans) => ans.questionIndex === questionIndex,
                );

                const answer = questAnswer ? questAnswer.answerIndex : null;
                console.log({ questAnswer, answer });

                return (
                  <Box
                    key={question.id}
                    display="flex"
                    flexDirection="column"
                    width="full"
                    paddingBlock={30}
                    borderBottom={'1.5px solid #F0EEEE'}
                  >
                    <div className="text-xl font-medium flex flex-row items-center gap-2 mb-4">
                      <div className="bg-[#3FB2D2] rounded-full w-6 h-6 min-w-6 min-h-6 text-base flex items-center justify-center text-white">
                        {questionIndex + 1}
                      </div>
                      <span className="!text-wrap text-start w-[calc(100%-2rem)]">
                        {question.content}
                      </span>
                    </div>
                    <CheckboxGroup
                      colorScheme="green"
                      defaultValue={[]}
                      value={answer ? [answer] : []}
                    >
                      <Stack
                        spacing={[1, 2]}
                        direction={['column']}
                        width="100%"
                      >
                        {question.options.map((option, index) => (
                          <Button
                            key={option}
                            value={index}
                            onClick={() => {
                              setQuestAnswers([
                                ...questAnswers.filter(
                                  (ans) => ans.questionIndex !== questionIndex,
                                ),
                                {
                                  questionIndex: questionIndex,
                                  answerIndex: index,
                                },
                              ]);

                              // Remove the question from the wrong answers list
                              //   setWrongAnswers(
                              //     wrongAnswers.filter(
                              //       (ans) => ans !== questionIndex,
                              //     ),
                              //   );
                            }}
                            variant={
                              answer === index
                                ? wrongAnswers.some(
                                    (ans) =>
                                      ans.questionIndex === questionIndex &&
                                      ans.answerIndex === index,
                                  )
                                  ? 'solid'
                                  : 'solid'
                                : 'outline'
                            }
                            className={`w-full !justify-between !text-wrap text-start ${
                              rightAnswers.some(
                                (ans) =>
                                  ans.questionIndex === questionIndex &&
                                  ans.answerIndex === index,
                              )
                                ? `!outline-green-500 ${
                                    answer === index
                                      ? '!bg-green-500/20 !text-black'
                                      : ''
                                  }`
                                : ''
                            } ${
                              wrongAnswers.some(
                                (ans) =>
                                  ans.questionIndex === questionIndex &&
                                  ans.answerIndex === index,
                              )
                                ? `!outline-red-500 ${
                                    answer === index
                                      ? '!bg-red-500/20 !text-black'
                                      : ''
                                  }`
                                : ''
                            }`}
                            isLoading={isSubmiting}
                            color={
                              rightAnswers.some(
                                (ans) =>
                                  ans.questionIndex === questionIndex &&
                                  ans.answerIndex === index,
                              )
                                ? 'green'
                                : wrongAnswers.some(
                                    (ans) =>
                                      ans.questionIndex === questionIndex &&
                                      ans.answerIndex === index,
                                  ) && questAnswer?.answerIndex === index
                                ? 'red'
                                : undefined
                            }
                          >
                            {option}
                            <div className="flex gap-1">
                              {/* {wrongAnswers
                                .filter(
                                  (ans) =>
                                    ans.questionIndex === questionIndex &&
                                    ans.answerIndex === index,
                                )
                                .map((ans) => (
                                  <div
                                    key={ans.toString()}
                                    className="bg-red-500 rounded-full w-4 h-4 text-xs flex items-center justify-center text-white"
                                  >
                                    X
                                  </div>
                                ))} */}
                              {wrongAnswers.some(
                                (ans) =>
                                  ans.questionIndex === questionIndex &&
                                  ans.answerIndex === index,
                              ) ? (
                                <div className="bg-red-500 rounded-full w-4 h-4 text-xs flex items-center justify-center text-white">
                                  X
                                </div>
                              ) : null}
                              {rightAnswers.some(
                                (ans) =>
                                  ans.questionIndex === questionIndex &&
                                  ans.answerIndex === index,
                              ) ? (
                                <div className="bg-green-500 rounded-full w-4 h-4 text-xs flex items-center justify-center text-white">
                                  V
                                </div>
                              ) : null}
                            </div>
                          </Button>
                        ))}
                      </Stack>
                    </CheckboxGroup>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
        <Button
          onClick={handleSubmit}
          colorScheme="blue"
          variant="solid"
          width="full"
          paddingBlock="10px"
          borderRadius="10px"
          marginTop="20px"
          marginBottom="20px"
        >
          {t('guidingQuestionnaire.submit')}
        </Button>
      </Box>
    </Container>
  );
}

export default GuidingQuestionnaireTest;
