import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Container,
  SlideFade,
  Image,
  SimpleGrid,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ExposureService from '../../services/exposure-service';
import TasklistService from '../../services/tasklist-service';
import UserService from '../../services/user-service';
import UtilSrvice from '../../services/util-service';
import style from '../../assets/style.json';
import { DashboardBox } from '../../components/Footer/styleComponent/DashboardBox';
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton';
import { BellIcon } from '../../assets/BellIcon';
import { AdminDashboardBox } from '../../components/Footer/styleComponent/AdminDashboardBox';
import { CompanyIcon } from '../../assets/CompanyIcon';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../locales/LocaleContext';
import DashboardSkelenton from '../../skelentons/DashboardSkelenton';
import GuidingQuestionnaireService, {
  GuidingQuestionnaire,
} from '../../services/guiding-questionnaire-service';

const Dashboard = ({
  role,
  isNewsletterOnly,
}: {
  role: any;
  isNewsletterOnly: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<any>(null);
  const [tasksCompleted, setTasksCompleted] = useState({
    checked: null,
    total: null,
  });
  const [exposure, setExposure] = useState<number | null>(null);
  const [avgExposure, setAvgExposure] = useState<number | null>(null);
  const [userTaskList, setUserTaskList] = useState<any>([]);
  const { isLoading, setIsLoading } = useContext(LanguageContext);
  const [isPc] = useMediaQuery('(min-width: 1300px)');

  const [questionnaires, setQuestionnaires] = useState<
    (GuidingQuestionnaire & {
      users: {
        userId: string;
        status: 'DONE' | 'PENDING';
      }[];
    })[]
  >([]);

  const doneQuestionnaires = questionnaires.filter((questionnaire) =>
    questionnaire.users.some(
      (user) => user.status === 'DONE' && user.userId === userInfo?.id,
    ),
  );
  const pendingQuestionnaires = questionnaires.filter((questionnaire) =>
    questionnaire.users.every(
      (user) => user.status !== 'DONE' || user.userId !== userInfo?.id,
    ),
  );
  const donePercentage = doneQuestionnaires.length / questionnaires.length;

  const load = async () => {
    setIsLoading(true);
    try {
      const user = await UserService.info();
      if (user) {
        setUserInfo(user);
      }
      const progress = user?.isNewsletterOnly
        ? null
        : role
        ? await TasklistService.progress(role?.id)
        : null;
      setTasksCompleted(progress || { checked: null, total: null });
      const exposure = user?.isNewsletterOnly
        ? null
        : await ExposureService.read();
      setExposure(exposure?.total ?? 0);
      const tasklists = user?.isNewsletterOnly
        ? []
        : await TasklistService.list('');
      setUserTaskList(tasklists);

      const questionnaires = await GuidingQuestionnaireService.list();
      setQuestionnaires((questionnaires as any) || []);

      if (user?.subCompanies && user?.subCompanies.length > 0) {
        const exposures = (await Promise.all(
          user.subCompanies.map((companyId: string) =>
            ExposureService.getById(companyId),
          ),
        )) as { total: number }[];

        const totalExposure = exposures.reduce(
          (acc, exposure) => acc + exposure.total,
          0,
        );

        const avgExposure = totalExposure / user.subCompanies.length;
        console.log({ totalExposure, avgExposure });

        setAvgExposure(avgExposure);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, [role]);

  const onClickTasks = async () => {
    if (isNewsletterOnly) {
      Swal.fire(t('dashboard.error'), t('dashboard.unauthorized'), 'error');
    } else {
      if (role?.id) {
        const id = userTaskList.find(
          (tasklist: any) => tasklist.roleId === role?.id,
        )?.id;
        navigate(`/tasks/${id ? id : userTaskList[0].id}`);
      }
    }
  };

  const onClickExposure = () => {
    if (isNewsletterOnly) {
      Swal.fire(t('dashboard.error'), t('dashboard.unauthorized'), 'error');
    } else {
      navigate(`/exposure`);
    }
  };

  const onClickCompaniesExposure = () => {
    if (isNewsletterOnly) {
      Swal.fire(t('dashboard.error'), t('dashboard.unauthorized'), 'error');
    } else {
      navigate(`/exposure-by-companies`);
    }
  };

  const isShowTogetherWin = true;

  return (
    <Container
      height={'100%'}
      maxW="full"
      py={20}
      bg={'transparent'}
      position="absolute"
      top={'90px'}
      centerContent
    >
      {isLoading ? (
        <DashboardSkelenton />
      ) : (
        <>
          <SlideFade in>
            {userInfo && (
              <>
                <Box
                  gap={2}
                  alignItems={'center'}
                  display={'flex'}
                  height={'80px'}
                ></Box>
              </>
            )}

            <Text
              color={'white'}
              dir="rtl"
              textAlign={'center'}
              marginBottom={isShowTogetherWin ? 37 : 117}
              fontWeight={500}
              fontSize={32}
            >
              {userInfo !== null
                ? t('dashboard.welcome') + ', ' + userInfo?.name?.split(' ')[0]
                : ''}
            </Text>
          </SlideFade>
          {userInfo?.company?.id !== '123' && userInfo !== null ? (
            <SimpleGrid
              maxW={style['pc-view']}
              w="100%"
              columns={isPc ? 2 : 1}
              spacingX={style.gap}
              spacingY={style.gap}
              paddingBottom={'100px'}
            >
              {(role?.showNewslatter || isNewsletterOnly) && (
                <DashboardBox order={3}>
                  <Container
                    display={'flex'}
                    alignItems="flex-start"
                    flexDirection="column"
                    justifyContent={'space-between'}
                    height={'100%'}
                  >
                    <Container
                      paddingInline={isPc ? '16px' : '0px'}
                      display={'flex'}
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Text
                        fontWeight="400"
                        fontSize={isPc ? 28 : 22}
                        color={style.black}
                        marginBottom={isPc ? '0px' : '30px'}
                      >
                        {t('dashboard.training')}
                      </Text>
                    </Container>
                    <PrimaryButton
                      onClick={() => {
                        navigate('/newslatter');
                      }}
                    >
                      {t('dashboard.viewTraining')}
                    </PrimaryButton>
                  </Container>

                  <Container
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding={'0px'}
                  >
                    <Box
                      display={'flex'}
                      alignItems="center"
                      justifyContent={'center'}
                      rounded="14px"
                      width={isPc ? '140px' : '85px'}
                      height={isPc ? '103px' : '63px'}
                      bg={'#F5F8FA'}
                      marginStart="auto"
                      position={'relative'}
                      padding={'0px'}
                    >
                      {userInfo?.newNewsCount > 0 && (
                        <Box
                          position={'absolute'}
                          top={'-20px'}
                          right={'-20px'}
                          width="35px"
                          height="35px"
                          display={'flex'}
                          alignItems="center"
                          justifyContent={'center'}
                          borderRadius="50%"
                          bg={'#25D366'}
                          color={'white'}
                          fontWeight={700}
                          // transform={
                          //   isPc
                          //     ? 'translate(180px, -50%)'
                          //     : 'translate(120px, -50%)'
                          // }
                          zIndex={100}
                        >
                          {userInfo?.newNewsCount}
                        </Box>
                      )}
                      <BellIcon scale={isPc ? 1 : 0.5} />
                    </Box>
                  </Container>
                </DashboardBox>
              )}

              {
                <>
                  <DashboardBox order={1}>
                    <Container
                      display={'flex'}
                      alignItems="flex-start"
                      flexDirection="column"
                      justifyContent={'space-between'}
                      height={'100%'}
                      marginInlineEnd="auto"
                      marginInlineStart="0px"
                    >
                      <Container
                        display={'flex'}
                        alignItems="flex-start"
                        paddingInline={isPc ? '16px' : '0px'}
                        flexDirection="column"
                      >
                        <Text
                          fontWeight="400"
                          fontSize={isPc ? 22 : 16}
                          color={style.grey1}
                        >
                          {UtilSrvice.timestampToDDMMYYYY(new Date().getTime())}
                        </Text>
                        <Text
                          fontWeight="400"
                          fontSize={isPc ? 28 : 22}
                          color={style.black}
                        >
                          {t('dashboard.myTasks')}
                        </Text>
                      </Container>
                      <PrimaryButton
                        disabled={!role}
                        onClick={() => onClickTasks()}
                      >
                        {t('dashboard.show')}
                      </PrimaryButton>
                    </Container>
                    <Box position={'relative'}>
                      {userInfo?.newTasksCount > 0 && (
                        <Box
                          position={'absolute'}
                          top={'0'}
                          right={'-10px'}
                          width="35px"
                          height="35px"
                          display={'flex'}
                          alignItems="center"
                          justifyContent={'center'}
                          borderRadius="50%"
                          bg={'#25D366'}
                          color={'white'}
                          fontWeight={700}
                          // transform={
                          //   isPc
                          //     ? 'translate(180px, -50%)'
                          //     : 'translate(120px, -50%)'
                          // }
                          zIndex={100}
                        >
                          {userInfo?.newTasksCount}
                        </Box>
                      )}

                      <Box position={'relative'}>
                        {userInfo?.newTasksCount > 0 && (
                          <Box
                            position={'absolute'}
                            top={'0'}
                            right={'-10px'}
                            width="35px"
                            height="35px"
                            display={'flex'}
                            alignItems="center"
                            justifyContent={'center'}
                            borderRadius="50%"
                            bg={'#25D366'}
                            color={'white'}
                            fontWeight={700}
                            // transform={
                            //   isPc
                            //     ? 'translate(180px, -50%)'
                            //     : 'translate(120px, -50%)'
                            // }
                            zIndex={100}
                          >
                            {userInfo?.newTasksCount}
                          </Box>
                        )}
                        <CircularProgress
                          mt={3}
                          value={
                            ((tasksCompleted.checked || 0) /
                              (tasksCompleted.total || 0)) *
                            100
                          }
                          size={isPc ? '150px' : '120px'}
                          thickness="10px"
                          color={
                            (tasksCompleted.checked || 0) >
                            0.6 * (tasksCompleted.total || 0)
                              ? '#3FB2D2'
                              : 'yellow.300'
                          }
                        >
                          <CircularProgressLabel fontWeight="bold">
                            {tasksCompleted.checked || 0}/
                            {tasksCompleted.total || '0'}
                          </CircularProgressLabel>
                        </CircularProgress>
                      </Box>
                    </Box>
                  </DashboardBox>

                  {(!!role?.showExposure || isNewsletterOnly) && (
                    <DashboardBox order={3}>
                      <Container
                        display={'flex'}
                        alignItems="flex-start"
                        flexDirection="column"
                        justifyContent={'space-between'}
                        height={'100%'}
                        marginInlineEnd="auto"
                        marginInlineStart="0px"
                      >
                        <Container
                          display={'flex'}
                          alignItems="flex-start"
                          paddingInline={isPc ? '16px' : '0px'}
                          flexDirection="column"
                        >
                          <Text
                            fontWeight="400"
                            fontSize={isPc ? 22 : 16}
                            color={style.grey1}
                          >
                            {UtilSrvice.timestampToDDMMYYYY(
                              new Date().getTime(),
                            )}
                          </Text>
                          <Text
                            fontWeight="400"
                            fontSize={isPc ? 28 : 22}
                            color={style.black}
                          >
                            {t('dashboard.performance')}
                          </Text>
                        </Container>
                        <PrimaryButton onClick={() => onClickExposure()}>
                          {t('dashboard.actions')}
                        </PrimaryButton>
                      </Container>
                      <CircularProgress
                        mt={3}
                        value={(exposure || 0) * 100}
                        size={isPc ? '150px' : '120px'}
                        thickness="10px"
                        color={
                          (exposure || 0) > 0.8
                            ? '#3FB2D2'
                            : (exposure || 0) > 0.4
                            ? '#F6A860'
                            : '#E55353'
                        }
                      >
                        <CircularProgressLabel
                          fontWeight="bold"
                          fontSize={exposure === null ? 15 : 28}
                          opacity={exposure === null ? 0.8 : 1}
                        >
                          {exposure === null
                            ? t('dashboard.processing') + '...'
                            : ((exposure || 0) * 100).toFixed(0) + '%'}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </DashboardBox>
                  )}

                  {userInfo.subCompanies && userInfo.subCompanies.length > 0 ? (
                    <DashboardBox order={3}>
                      <Container
                        display={'flex'}
                        alignItems="flex-start"
                        flexDirection="column"
                        justifyContent={'space-between'}
                        height={'100%'}
                        marginInlineEnd="auto"
                        marginInlineStart="0px"
                      >
                        <Container
                          display={'flex'}
                          alignItems="flex-start"
                          paddingInline={isPc ? '16px' : '0px'}
                          flexDirection="column"
                        >
                          <Text
                            fontWeight="400"
                            fontSize={isPc ? 22 : 16}
                            color={style.grey1}
                          >
                            {UtilSrvice.timestampToDDMMYYYY(
                              new Date().getTime(),
                            )}
                          </Text>
                          <Text
                            fontWeight="400"
                            fontSize={isPc ? 28 : 22}
                            color={style.black}
                          >
                            {t('dashboard.companiesPerformance')}
                          </Text>
                        </Container>
                        <PrimaryButton
                          onClick={() => onClickCompaniesExposure()}
                        >
                          {t('dashboard.viewCompanies')}
                        </PrimaryButton>
                      </Container>
                      <CircularProgress
                        mt={3}
                        value={(avgExposure || 0) * 100}
                        size={isPc ? '150px' : '120px'}
                        thickness="10px"
                        color={
                          (avgExposure || 0) > 0.8
                            ? '#3FB2D2'
                            : (avgExposure || 0) > 0.4
                            ? '#F6A860'
                            : '#E55353'
                        }
                      >
                        <CircularProgressLabel
                          fontWeight="bold"
                          fontSize={avgExposure === null ? 15 : 28}
                          opacity={avgExposure === null ? 0.8 : 1}
                        >
                          {avgExposure === null
                            ? t('dashboard.processing') + '...'
                            : ((avgExposure || 0) * 100).toFixed(0) + '%'}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </DashboardBox>
                  ) : null}

                  {!!role?.showQuestionnaires && (
                    <DashboardBox order={4}>
                      <Container
                        display={'flex'}
                        alignItems="flex-start"
                        flexDirection="column"
                        justifyContent={'space-between'}
                        height={'100%'}
                        marginInlineEnd="auto"
                        marginInlineStart="0px"
                      >
                        <Container
                          display={'flex'}
                          alignItems="flex-start"
                          paddingInline={isPc ? '16px' : '0px'}
                          flexDirection="column"
                        >
                          <Text
                            fontWeight="400"
                            fontSize={isPc ? 28 : 22}
                            color={style.black}
                          >
                            {t('dashboard.questionnaires')}
                          </Text>
                          {pendingQuestionnaires.length > 0 ? (
                            <Text
                              fontWeight="400"
                              fontSize={isPc ? 22 : 16}
                              color={style.grey1}
                            >
                              {t('guidingQuestionnaire.fillMore').replace(
                                '{pendingQuestionnaires}',
                                pendingQuestionnaires.length.toString(),
                              )}
                            </Text>
                          ) : (
                            <Text
                              fontWeight="400"
                              fontSize={isPc ? 22 : 16}
                              color={style.grey1}
                            >
                              {t('guidingQuestionnaire.noQuestionnairesToFill')}
                            </Text>
                          )}
                        </Container>
                        <PrimaryButton
                          onClick={() => {
                            navigate('/guiding-questionnaire');
                          }}
                        >
                          {t('dashboard.showQuestionnaires')}
                        </PrimaryButton>
                      </Container>
                      <CircularProgress
                        mt={3}
                        value={(donePercentage || 0) * 100}
                        size={isPc ? '150px' : '120px'}
                        thickness="10px"
                        color={
                          (donePercentage || 0) > 0.8
                            ? '#3FB2D2'
                            : (donePercentage || 0) > 0.4
                            ? '#F6A860'
                            : '#E55353'
                        }
						position={'relative'}
                      >
						{userInfo?.newQuestionnairesCount > 0 && (
                            <Box
                              position={'absolute'}
                              top={'-10px'}
                              right={'-10px'}
                              width="35px"
                              height="35px"
                              display={'flex'}
                              alignItems="center"
                              justifyContent={'center'}
                              borderRadius="50%"
                              bg={'#25D366'}
                              color={'white'}
                              fontWeight={700}
							  fontSize={15}
                              // transform={
                              //   isPc
                              //     ? 'translate(180px, -50%)'
                              //     : 'translate(120px, -50%)'
                              // }
                              zIndex={100}
                            >
                              {userInfo?.newQuestionnairesCount}
                            </Box>
                          )}
                        <CircularProgressLabel
                          fontWeight="bold"
                          fontSize={donePercentage === null ? 15 : 28}
                          opacity={donePercentage === null ? 0.8 : 1}
                        >
                          {doneQuestionnaires.length}/{questionnaires.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </DashboardBox>
                  )}
                </>
              }
            </SimpleGrid>
          ) : (
            <Box
              maxW={style['pc-view']}
              w="100%"
              paddingBottom={'100px'}
              gap={isPc ? style.gap : '10px'}
              display={isPc ? 'flex' : 'grid'}
              // gridTemplateColumns={isPc ? "unset" : "1fr 1fr"}
              gridTemplateColumns={isPc ? 'unset' : 'repeat(2, minmax(0, 1fr))'}
              justifyContent={'center'}
              flexWrap="wrap"
            >
              {userInfo && (
                <>
                  <AdminDashboardBox order={isPc ? 4 : 3} isPc={isPc}>
                    <Text fontWeight="400" fontSize={22} color={style.black}>
                      {t('dashboard.training')}
                    </Text>
                    {userInfo?.newNewsCount > 0 && (
                      <Box
                        position={'absolute'}
                        top={'100px'}
                        right={'50%'}
                        width="35px"
                        height="35px"
                        display={'flex'}
                        alignItems="center"
                        justifyContent={'center'}
                        borderRadius="50%"
                        bg={'#25D366'}
                        color={'white'}
                        fontWeight={700}
                        transform={'translate(80px, -50%)'}
                        zIndex={100}
                      >
                        {userInfo?.newNewsCount}
                      </Box>
                    )}
                    <Box
                      width={'100%'}
                      marginTop="20px"
                      marginInline="auto"
                      border={'1px solid #D4E7EC'}
                    />
                    <Box
                      display={'flex'}
                      alignItems="center"
                      justifyContent={'center'}
                      rounded="14px"
                      width="85px"
                      height={'63px'}
                      bg={'#F5F8FA'}
                      marginInline="auto"
                      padding={'0px'}
                      marginTop="20px"
                    >
                      <BellIcon scale={0.6} />
                    </Box>

                    <Box
                      display={'flex'}
                      flexWrap={'wrap'}
                      gap={'15px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      mt={'40px'}
                    >
                      {userInfo?.company?.id === '123' && (
                        <Button
                          color={style['primary-color']}
                          minW={isPc ? '188px' : '143px'}
                          textAlign="center"
                          background={'transparent'}
                          fontSize="18px"
                          fontWeight={700}
                          rounded={'100px'}
                          border={`1px solid ${style['primary-color']}`}
                          onClick={() => {
                            navigate('/admin/modify-newslatter');
                          }}
                        >
                          {t('dashboard.addTraining')}
                        </Button>
                      )}

                      <PrimaryButton
                        onClick={() => {
                          navigate('/newslatter');
                        }}
                      >
                        {t('dashboard.viewTraining')}
                      </PrimaryButton>
                    </Box>
                  </AdminDashboardBox>

                  <AdminDashboardBox order={isPc ? 4 : 3} isPc={isPc}>
                    <Text fontWeight="400" fontSize={22} color={style.black}>
                      {t('dashboard.questionnaire')}
                    </Text>
                    {userInfo?.newNewsCount > 0 && (
                      <Box
                        position={'absolute'}
                        top={'100px'}
                        right={'50%'}
                        width="35px"
                        height="35px"
                        display={'flex'}
                        alignItems="center"
                        justifyContent={'center'}
                        borderRadius="50%"
                        bg={'#25D366'}
                        color={'white'}
                        fontWeight={700}
                        transform={'translate(80px, -50%)'}
                        zIndex={100}
                      >
                        {userInfo?.newNewsCount}
                      </Box>
                    )}
                    <Box
                      width={'100%'}
                      marginTop="20px"
                      marginInline="auto"
                      border={'1px solid #D4E7EC'}
                    />
                    <Box
                      display={'flex'}
                      alignItems="center"
                      justifyContent={'center'}
                      rounded="14px"
                      width="85px"
                      height={'63px'}
                      bg={'#F5F8FA'}
                      marginInline="auto"
                      padding={'0px'}
                      marginTop="20px"
                    >
                      <BellIcon scale={0.6} />
                    </Box>

                    <Box
                      display={'flex'}
                      flexWrap={'wrap'}
                      gap={'15px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      mt={'40px'}
                    >
                      {userInfo?.company?.id === '123' && (
                        <Button
                          color={style['primary-color']}
                          minW={isPc ? '188px' : '143px'}
                          textAlign="center"
                          background={'transparent'}
                          fontSize="18px"
                          fontWeight={700}
                          rounded={'100px'}
                          border={`1px solid ${style['primary-color']}`}
                          onClick={() => {
                            navigate('/admin/modify-guiding-questionnaire');
                          }}
                        >
                          {t('dashboard.addQuestionnaire')}
                        </Button>
                      )}

                      <PrimaryButton
                        onClick={() => {
                          navigate('/guiding-questionnaire');
                        }}
                      >
                        {t('dashboard.viewQuestionnaire')}
                      </PrimaryButton>
                    </Box>
                  </AdminDashboardBox>

                  <AdminDashboardBox order={isPc ? 3 : 4} isPc={isPc}>
                    <Text fontWeight="400" fontSize={22} color={style.black}>
                      {t('dashboard.performance')}
                    </Text>
                    <Text fontWeight="400" fontSize={18} color={style.grey1}>
                      {UtilSrvice.timestampToDDMMYYYY(new Date().getTime())}
                    </Text>

                    <Box>
                      <CircularProgress
                        mt={3}
                        mb={3}
                        value={(exposure || 0) * 100}
                        size="150px"
                        thickness="10px"
                        color={
                          (exposure || 0) > 0.8
                            ? '#3FB2D2'
                            : (exposure || 0) > 0.4
                            ? '#F6A860'
                            : '#E55353'
                        }
                      >
                        <CircularProgressLabel
                          fontWeight="bold"
                          fontSize={exposure === null ? 15 : 28}
                          opacity={exposure === null ? 0.8 : 1}
                        >
                          {exposure === null
                            ? t('dashboard.processing') + '...'
                            : ((exposure || 0) * 100).toFixed(0) + '%'}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </Box>

                    <PrimaryButton onClick={() => navigate(`/exposure`)}>
                      {t('dashboard.actions')}
                    </PrimaryButton>
                  </AdminDashboardBox>

                  {userInfo.subCompanies && userInfo.subCompanies.length > 0 ? (
                    <AdminDashboardBox order={isPc ? 3 : 4} isPc={isPc}>
                      <Text fontWeight="400" fontSize={22} color={style.black}>
                        {t('dashboard.companiesPerformance')}
                      </Text>
                      <Text fontWeight="400" fontSize={18} color={style.grey1}>
                        {UtilSrvice.timestampToDDMMYYYY(new Date().getTime())}
                      </Text>

                      <Box>
                        <CircularProgress
                          mt={3}
                          mb={3}
                          value={(exposure || 0) * 100}
                          size="150px"
                          thickness="10px"
                          color={
                            (exposure || 0) > 0.8
                              ? '#3FB2D2'
                              : (exposure || 0) > 0.4
                              ? '#F6A860'
                              : '#E55353'
                          }
                        >
                          <CircularProgressLabel
                            fontWeight="bold"
                            fontSize={exposure === null ? 15 : 28}
                            opacity={exposure === null ? 0.8 : 1}
                          >
                            {exposure === null
                              ? t('dashboard.processing') + '...'
                              : ((exposure || 0) * 100).toFixed(0) + '%'}
                          </CircularProgressLabel>
                        </CircularProgress>
                      </Box>

                      <PrimaryButton onClick={() => onClickCompaniesExposure()}>
                        {t('dashboard.viewCompanies')}
                      </PrimaryButton>
                    </AdminDashboardBox>
                  ) : null}

                  <AdminDashboardBox order={isPc ? 2 : 2} isPc={isPc}>
                    <Text fontWeight="400" fontSize={22} color={style.black}>
                      {t('dashboard.companies')}
                    </Text>
                    <Box
                      width={'100%'}
                      marginTop="20px"
                      marginInline="auto"
                      border={'1px solid #D4E7EC'}
                    />
                    <Box
                      display={'flex'}
                      alignItems="center"
                      justifyContent={'center'}
                      rounded="14px"
                      width="85px"
                      height={'63px'}
                      bg={'#F5F8FA'}
                      marginInline="auto"
                      padding={'0px'}
                      marginTop="20px"
                    >
                      <CompanyIcon />
                    </Box>
                    <Box
                      display={'flex'}
                      flexWrap={'wrap'}
                      gap={'15px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      mt={'40px'}
                    >
                      {userInfo?.company?.id === '123' && (
                        <Button
                          color={style['primary-color']}
                          minW={isPc ? '188px' : '143px'}
                          textAlign="center"
                          background={'transparent'}
                          fontSize="18px"
                          fontWeight={700}
                          rounded={'100px'}
                          border={`1px solid ${style['primary-color']}`}
                          onClick={() => {
                            navigate('/admin/company');
                          }}
                        >
                          {t('dashboard.addCompany')}
                        </Button>
                      )}

                      <PrimaryButton
                        onClick={() => {
                          navigate('/admin/companies');
                        }}
                      >
                        {t('dashboard.viewCompanies')}
                      </PrimaryButton>
                    </Box>
                  </AdminDashboardBox>

                  <AdminDashboardBox order={isPc ? 1 : 1} isPc={isPc}>
                    <Text fontWeight="400" fontSize={22} color={style.black}>
                      {t('dashboard.myTasks')}
                    </Text>
                    <Text fontWeight="400" fontSize={18} color={style.grey1}>
                      {UtilSrvice.timestampToDDMMYYYY(new Date().getTime())}
                    </Text>

                    <Box>
                      <CircularProgress
                        mt={3}
                        mb={3}
                        value={
                          ((tasksCompleted.checked || 0) /
                            (tasksCompleted.total || 0)) *
                          100
                        }
                        size="150px"
                        thickness="10px"
                        color={
                          (tasksCompleted.checked || 0) >
                          0.6 * (tasksCompleted.total || 0)
                            ? '#3FB2D2'
                            : 'yellow.300'
                        }
                      >
                        <CircularProgressLabel fontWeight="bold">
                          {tasksCompleted.checked || 0}/
                          {tasksCompleted.total || '0'}
                        </CircularProgressLabel>
                      </CircularProgress>
                      {userInfo?.newTasksCount > 0 && (
                        <Box
                          position={'absolute'}
                          top={'100px'}
                          right={'50%'}
                          width="35px"
                          height="35px"
                          display={'flex'}
                          alignItems="center"
                          justifyContent={'center'}
                          borderRadius="50%"
                          bg={'#25D366'}
                          color={'white'}
                          fontWeight={700}
                          transform={'translate(80px, -50%)'}
                          zIndex={100}
                        >
                          {userInfo?.newTasksCount}
                        </Box>
                      )}
                    </Box>

                    <PrimaryButton onClick={() => onClickTasks()}>
                      {t('dashboard.show')}
                    </PrimaryButton>
                  </AdminDashboardBox>
                </>
              )}
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default Dashboard;
