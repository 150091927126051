import {
  Box,
  Button,
  Container,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Input,
  Textarea,
  forwardRef,
} from '@chakra-ui/react';
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton';
import { CirclePlusIcon } from '../../assets/CirclePlusIcon';
import { TrashIcon } from '../../assets/TrashIcon';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import styles from './markdown-styles.module.css';
import style from '../../assets/style.json';
import ReactMarkdown from 'react-markdown';

const translationKey = 'botMarkdownPopup.';

const TYPES = ['TEXT', 'LONG_TEXT', 'LIST', 'NUM_LIST'] as const;

const genId = () => Math.random().toString(36).substring(7);

const Markdown = () => {
  const { t } = useTranslation();

  // Title will be the risk title by default
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [items, setItems] = useState<Item[]>([]);
  const [view, setView] = useState(false);

  const isTitleValid = title.length > 0;
  const isDescriptionValid = description.length > 0;
  const isItemsValid = items.length;
  console.log({ isTitleValid, isDescriptionValid, isItemsValid });
  const isValid = isTitleValid && isDescriptionValid && isItemsValid;

  console.log({ isValid });

  console.log({
    test: jsonToMarkdown({ title, description, items }),
    toJson: markdownToJson(jsonToMarkdown({ title, description, items })),
    items,
  });

  // Download the markdown file
  const handleDownloadMdFile = () => {
    const markdown = jsonToMarkdown({ title, description, items });
    const element = document.createElement('a');
    const file = new Blob([markdown], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'markdown.md';
    document.body.appendChild(element);
    element.click();
  };

  const handleUploadMdFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target?.result as string;
      const doc = markdownToJson(content);

      setTitle(doc.title);
      setDescription(doc.description);
      setItems(doc.items);
    };

    reader.readAsText(file);
  };

  return (
    <Container
      maxW="full"
      minH="100vh"
      position={'absolute'}
      top={'190px'}
      py={20}
      centerContent
    >
      <Text
        color={'white'}
        dir="rtl"
        marginBottom={'0px'}
        fontWeight={500}
        fontSize={22}
      >
        {t(translationKey + 'title')}
      </Text>
      <Box
        padding={'22px'}
        width={'100%'}
        rounded="10px"
        bg={'#fff'}
        w="100%"
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08);"
        mt={10}
        paddingInline={'28px'}
        display={'flex'}
        flexDirection="column"
        maxW={style['pc-view']}
        gap={'20px'}
      >
        {view ? (
          <div className={styles.markdownBody}>
            <MarkdownPreview
              content={jsonToMarkdown({ title, description, items })}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-6">
            <input
              id="file" // Add this line
              type="file"
              onChange={handleUploadMdFile}
              accept=".md"
              className="hidden"
            />
            <PrimaryButton
              onClick={() => document.getElementById('file')?.click()}
              styles={{
                backgroundColor: style['background'],
                color: '#000',
                border: '1px solid #00000020',
              }}
            >
              {t(translationKey + 'buttons.upload')}
            </PrimaryButton>
            <hr />

            <TextInput
              label={t(translationKey + 'titleInput.label')}
              value={title}
              setValue={setTitle}
            />
            <TextInput
              label={t(translationKey + 'description.label')}
              value={description}
              setValue={setDescription}
              type="textarea"
            />
            {items.map((item) => (
              <ItemBox
                {...item}
                setItem={(newItem) => {
                  const index = items.findIndex((i) => i.id === item.id);
                  const newItems = [...items];
                  newItems[index] = { ...newItems[index], ...newItem };
                  setItems(newItems);
                }}
                removeItem={() => {
                  const newItems = items.filter((i) => i.id !== item.id);
                  setItems(newItems);
                }}
                key={item.id}
                isBase
              />
            ))}
            <AddMoreDropdown
              addItem={(type: Type) => {
                const stringOrArray =
                  type === 'LIST' || type === 'NUM_LIST' ? [] : '';

                setItems([
                  ...items,
                  {
                    id: genId(),
                    title: '',
                    type,
                    value: stringOrArray,
                  },
                ]);
              }}
            />
          </div>
        )}
        <div className="flex gap-4">
          <PrimaryButton
            onClick={() => setView(!view)}
            styles={{ width: '100%' }}
          >
            {view
              ? t(translationKey + 'buttons.view.back')
              : t(translationKey + 'buttons.view.toggle')}
          </PrimaryButton>
          <PrimaryButton
            styles={{ width: '100%' }}
            onClick={isValid ? handleDownloadMdFile : () => {}}
            disabled={!isValid}
          >
            {t(translationKey + 'buttons.save')}
          </PrimaryButton>
        </div>
      </Box>
    </Container>
  );
};

const AddMoreDropdown = ({ addItem }: { addItem: (type: Type) => void }) => {
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuButton as={Button}>
        <span className="flex items-center justify-center gap-2">
          <CirclePlusIcon /> {t(translationKey + 'buttons.add')}
        </span>
      </MenuButton>
      <MenuList>
        {TYPES.map((type) => (
          <MenuItem onClick={() => addItem(type)} key={type}>
            {t(translationKey + 'types.' + type)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

type Type = (typeof TYPES)[number];

type Item = {
  id: string;
  title: string;
  type: Type;
  value: string | string[];
  subItems?: Item[];
};

const ItemBox = ({
  id,
  title,
  type,
  value,
  subItems,
  setItem,
  removeItem,
  isBase,
}: {
  setItem: (item: Partial<Item>) => void;
  removeItem: () => void;
  isBase?: boolean;
} & Item) => {
  const { t } = useTranslation();

  return (
    <Box
      padding={'22px'}
      width={'100%'}
      rounded="10px"
      bg="#F5F8FA"
      border={'1px solid #E2E2E2'}
      w="100%"
      boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08);"
      paddingInline={'20px'}
      display={'flex'}
      flexDirection="column"
    >
      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-4 w-full">
          <TextInput
            label={t(translationKey + 'titleInput.label')}
            value={title}
            setValue={(value) => setItem({ title: value })}
          />
          <ItemBoxType
            id={id}
            title={title}
            type={type}
            value={value}
            setItem={setItem}
          />
          {subItems?.map((item) => (
            <ItemBox
              {...item}
              setItem={(newItem) => {
                const index = subItems.findIndex((i) => i.id === item.id);
                const newItems = [...subItems];
                newItems[index] = { ...newItems[index], ...newItem };
                setItem({ subItems: newItems });
              }}
              removeItem={() => {
                const newItems = subItems.filter((i) => i.id !== item.id);
                setItem({ subItems: newItems });
              }}
              key={item.id}
            />
          ))}
          {isBase && (
            <AddMoreDropdown
              addItem={(type: Type) => {
                const stringOrArray =
                  type === 'LIST' || type === 'NUM_LIST' ? [] : '';

                setItem({
                  subItems: [
                    ...(subItems || []),
                    {
                      id: genId(),
                      title: '',
                      type,
                      value: stringOrArray,
                    },
                  ],
                });
              }}
            />
          )}
        </div>
        <Button
          rounded="100%"
          aspectRatio={1}
          p={3}
          border="1px solid #E2E2E2"
          onClick={removeItem}
        >
          <TrashIcon />
        </Button>
      </div>
    </Box>
  );
};

const ItemBoxType = ({
  type,
  value,
  setItem,
}: {
  setItem: (item: Partial<Item>) => void;
} & Item) => {
  const { t } = useTranslation();

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  //   useEffect(() => {
  //     inputRefs.current.forEach((input, index) => {
  //       if (input && index === inputRefs.current.length - 1) {
  //         input.focus();
  //       }
  //     });
  //   }, [value]);

  switch (type) {
    case 'TEXT':
      return (
        <TextInput
          label={t(translationKey + 'items.value')}
          value={value as string}
          setValue={(value) => setItem({ value })}
        />
      );

    case 'LONG_TEXT':
      return (
        <TextInput
          label={t(translationKey + 'items.value')}
          value={value as string}
          setValue={(value) => setItem({ value })}
          type="textarea"
        />
      );

    case 'LIST':
    case 'NUM_LIST':
      return (
        <div className="flex flex-col gap-4">
          <Text fontSize={'14px'} color="#aaa" fontWeight="14px">
            {t(translationKey + 'items.value')}
          </Text>
          {(value as string[]).map((val, index) => (
            <TextInput
              key={index}
              value={val}
              // bullet point
              label={type === 'LIST' ? '•' : `${index + 1}.`}
              labelPlacement="start"
              setValue={(v) => {
                // If blank, remove
                if (!v) {
                  const newItems = [...(value as string[])];
                  newItems.splice(index, 1);
                  setItem({ value: newItems });
                  return;
                }

                const newItems = [...(value as string[])];
                newItems[index] = v;

                setItem({ value: newItems });
              }}
              ref={(el: any) => (inputRefs.current[index] = el)}
            />
          ))}
          <TextInput
            placeholder={t(translationKey + 'items.add')}
            value=""
            label="+"
            labelPlacement="start"
            setValue={(v) => {
              setItem({ value: [...(value as string[]), v] });
              // Focus created input
              setTimeout(() => {
                const lastIndex = inputRefs.current.length - 1;
                if (inputRefs.current[lastIndex]) {
                  inputRefs.current[lastIndex]?.focus();
                }
              }, 0);
            }}
          />
        </div>
      );
  }
};

const TextInput = forwardRef(
  (
    {
      label,
      placeholder,
      value,
      setValue,
      type = 'text',
      labelPlacement = 'top',
    }: {
      label?: string;
      placeholder?: string;
      value: string;
      setValue: (value: string) => void;
      type?: 'text' | 'textarea';
      labelPlacement?: 'top' | 'start';
    },
    ref,
  ) => {
    return (
      <div
        className={
          labelPlacement === 'top'
            ? 'flex flex-col gap-1.5'
            : 'flex flex-row items-center gap-1.5'
        }
      >
        {label && (
          <Text fontSize={'14px'} color="#aaa" fontWeight="14px">
            {label}
          </Text>
        )}
        {type === 'text' ? (
          <Input
            bg="#fff"
            height={50}
            border={'1px solid #E2E2E2'}
            rounded="10px"
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            ref={ref}
          />
        ) : (
          <Textarea
            bg="#fff"
            height={100}
            border={'1px solid #E2E2E2'}
            rounded="10px"
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            ref={ref}
          />
        )}
      </div>
    );
  },
);

export default Markdown;

type Document = {
  title: string;
  description: string;
  items: Item[];
};

const jsonToMarkdown = (doc: Document): string => {
  let markdown = `# ${doc.title}\n\n${doc.description}\n\n`;

  function processItem(item: Item, depth: number = 2): string {
    let itemMd = `\n${'#'.repeat(depth)} ${item.title}\n`;

    switch (item.type) {
      case 'TEXT':
      case 'LONG_TEXT':
        itemMd += `${item.value}\n`;
        break;
      case 'LIST':
        if (Array.isArray(item.value)) {
          item.value.forEach((listItem) => {
            itemMd += `- ${listItem}\n`;
          });
        }
        break;
      case 'NUM_LIST':
        if (Array.isArray(item.value)) {
          item.value.forEach((listItem, index) => {
            itemMd += `${index + 1}. ${listItem}\n`;
          });
        }
        break;
    }

    if (item.subItems) {
      item.subItems.forEach((subItem) => {
        itemMd += processItem(subItem, depth + 1);
      });
    }

    return itemMd;
  }

  doc.items.forEach((item) => {
    markdown += processItem(item);
  });

  return markdown.trim();
};

const markdownToJson = (markdown: string): Document => {
  const lines = markdown.split('\n');
  const document: Document = { title: '', description: '', items: [] };
  let currentItem: Item | null = null;
  let currentSubItems: Item[] = [];
  let currentDepth = 0;
  let descriptionLines: string[] = [];

  lines.forEach((line, index) => {
    const trimmedLine = line.trim();
    if (index === 0 && trimmedLine.startsWith('# ')) {
      document.title = trimmedLine.substring(2);
    } else if (trimmedLine.startsWith('## ')) {
      if (currentItem) {
        document.items.push(currentItem);
      }
      currentItem = {
        id: genId(),
        title: trimmedLine.substring(3),
        type: 'TEXT',
        value: '',
      };
      currentSubItems = [];
      currentDepth = 2;
    } else if (trimmedLine.startsWith('### ') && currentItem) {
      currentSubItems.push({
        id: genId(),
        title: trimmedLine.substring(4),
        type: 'TEXT',
        value: '',
      });
      currentDepth = 3;
    } else if (trimmedLine.startsWith('-') || /^\d+\./.test(trimmedLine)) {
      const listItem = trimmedLine.substring(trimmedLine.indexOf(' ') + 1);
      const targetItem =
        currentDepth === 2
          ? currentItem
          : currentSubItems[currentSubItems.length - 1];

      if (targetItem) {
        if (!Array.isArray(targetItem.value)) {
          targetItem.value = [];
        }
        (targetItem.value as string[]).push(listItem);
        targetItem.type = trimmedLine.startsWith('-') ? 'LIST' : 'NUM_LIST';
      }
    } else if (trimmedLine !== '') {
      if (currentDepth === 0) {
        descriptionLines.push(trimmedLine);
      } else {
        const targetItem =
          currentDepth === 2
            ? currentItem
            : currentSubItems[currentSubItems.length - 1];

        if (targetItem) {
          if (typeof targetItem.value === 'string') {
            targetItem.value += (targetItem.value ? '\n' : '') + trimmedLine;
          } else {
            targetItem.value = trimmedLine;
          }
          targetItem.type = targetItem.value.includes('\n')
            ? 'LONG_TEXT'
            : 'TEXT';
        }
      }
    }
  });

  if (currentItem) {
    if (currentSubItems.length > 0) {
      (currentItem as any).subItems = currentSubItems;
    }
    document.items.push(currentItem);
  }

  document.description = descriptionLines.join('\n').trim();
  return document;
};

interface MarkdownPreviewProps {
  content: string;
}

const MarkdownPreview: React.FC<MarkdownPreviewProps> = ({ content }) => {
  return (
    <div className="markdown-preview">
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};
