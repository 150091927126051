import z from 'zod';

// Personal Information Schema
const personalInfoSchema = z.object({
  reporterName: z.string(),
  reporterRole: z.string(),
  reporterHostNum: z.string(),
  date: z.string().min(3),
  subjectLastName: z.string(),
  subjectFirstName: z.string(),
  subjectId: z.string(),
  subjectSex: z.string(),
  subjectBirthDate: z.string().min(2),
  subjectMaritalStatus: z.string(),
  subjectKidsAmount: z.string(),
  subjectAddressStreet: z.string(),
  subjectAddressStreetNumber: z.string(),
  subjectAddressCity: z.string(),
  subjectAddressZip: z.string(),
  subjectPhone: z.string(),
});

// Event Information Schema
const eventInfoSchema = z.object({
  distinctions: z.string(),
  beforeEventConsciousnessEnum: z.enum([
    'full',
    'partial',
    'confused',
    'unconscious',
    'other',
  ]),
  subjectBeforeEventConsciousnessOther: z
    .string()
    .nullable()
    .nullish()
    .optional(),
  fallMobilityBeforeEventEnum: z
    .enum(['bedridden', 'limited', 'self', 'other_with_tools'])
    .optional()
    .nullable(),
  fallMobilityBed: z
    .enum(['risen', 'not_risen', 'no_sides'])
    .optional()
    .nullable(),
  eventDate: z.string().min(3),
  eventTime: z.string().min(2),
  eventDay: z.enum(['SATURDAY', 'WEEKDAY', 'HOLIDAY']),
  //   shiftTime: z.enum(['MORNING', 'AFTERNOON', 'NIGHT']),
  shiftTime: z.string(),
  //   eventLocation: z.enum(['PLATOON', 'CLINIC', 'INSTITUTE']),
  eventLocation: z.string(),
  eventLocationSpecific: z.string().nullable(),
  eventDescription: z.string(),
  eventResults: z.string(),
});

// Staff Information Schema
const staffInfoSchema = z.object({
  staffInvolvedName1: z.string(),
  staffInvolvedRole1: z.string(),
  staffInvolvedName2: z.string().nullable(),
  staffInvolvedRole2: z.string().nullable(),
});

// Main Schema
const redButtonSchema = z.object({
  ...personalInfoSchema.shape,
  ...eventInfoSchema.shape,
  ...staffInfoSchema.shape,
});

export default redButtonSchema;
